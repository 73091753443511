import { DashboardConfigDef as CoreDashboardConfigDef } from '@ade/core/src/config';
import { DashboardConfigDef as InventoryDashboardConfigDef } from '@ade/inventory/src/config';
// NOTE: will only include for dev for now.  Will likely be removed and only include for dkm specific app
import { DashboardConfigDef as ReportDashboardConfigDef } from '@ade/report/src/config';

let dashboardStats = [];
let dashboardComponents = [];

dashboardStats = dashboardStats.concat(CoreDashboardConfigDef.dashboardStats);
dashboardStats = dashboardStats.concat(InventoryDashboardConfigDef.dashboardStats);
dashboardStats = dashboardStats.concat(ReportDashboardConfigDef.dashboardStats);

dashboardComponents = dashboardComponents.concat(CoreDashboardConfigDef.dashboardComponents);
dashboardComponents = dashboardComponents.concat(InventoryDashboardConfigDef.dashboardComponents);
dashboardComponents = dashboardComponents.concat(ReportDashboardConfigDef.dashboardComponents);

export const DashboardConfigDef = {
    dashboardStats: dashboardStats,
    dashboardComponents: dashboardComponents
};
