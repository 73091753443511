import { ModelConfigDef as AuthModelConfigDef } from '@ade/auth/src/config';
import { ModelConfigDef as CoreModelConfigDef } from '@ade/core/src/config';
import { ModelConfigDef as InventoryModelConfigDef } from '@ade/inventory/src/config';
import { ModelConfigDef as SfdcModelConfigDef } from '@ade/sfdc/src/config';
import { ModelConfigDef as SystemModelConfigDef } from '@ade/system/src/config';

let models = [];
models = models.concat(AuthModelConfigDef.models);
models = models.concat(CoreModelConfigDef.models);
models = models.concat(InventoryModelConfigDef.models);
models = models.concat(SystemModelConfigDef.models);
models = models.concat(SfdcModelConfigDef.models);

export const ModelConfigDef = {
    models: models
};
