import { NavConfigDef as AdminNavConfigDef } from '@ade/admin/src/config';
import { NavConfigDef as CoreNavConfigDef } from '@ade/core/src/config';
import { NavConfigDef as InventoryNavConfigDef } from '@ade/inventory/src/config';

let navItems = [];
navItems = navItems.concat(CoreNavConfigDef.navItems);
navItems = navItems.concat(InventoryNavConfigDef.navItems);
navItems = navItems.concat(AdminNavConfigDef.navItems);

export const NavConfigDef = {
    navItems: navItems
};
