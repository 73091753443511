import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';

// Ngx Bootstrap Components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Core helpers
import { APP_CONFIG, DASHBOARD_CONFIG, MODEL_CONFIG, NAV_CONFIG } from '@ade/core/src/config';
import { GlobalErrorHandler } from '@ade/core/src/handlers';
import {
    AuthGuard,
    AcceptTcGuard,
    FinishGuard,
    LpTagGuard,
    OauthGuard,
    ResetPasswordGuard,
    RtaGuard,
    TdiDemoGuard
} from '@ade/core/src/guards';

// 3rd Party modules
import { TabsModule } from 'ngx-bootstrap/tabs';
import { InViewportModule } from 'ng-in-viewport';
import { AngularResizedEventModule } from 'angular-resize-event';

// App components and internal modules
import { AppComponent } from './app.component';
import { AdminModule } from '@ade/admin/src';
import { AuthModule } from '@ade/auth/src';
import { CoreModule } from '@ade/core/src';
import { InventoryModule } from '@ade/inventory/src';
import { ReportModule } from '@ade/report/src';
import { SfdcModule } from '@ade/sfdc/src';
import { SystemModule } from '@ade/system/src';

// Http Interceptors
import { AuthInterceptor, TokenInterceptor } from '@ade/core/src/http/auth.interceptor';
// Import abilities
import { AppAbilityModule } from '@ade/app/src/app.ability';
// Import routing
import { AppRoutingModule } from './app.routing';
import {
    AppConfigDef,
    DashboardConfigDef,
    ModelConfigDef,
    NavConfigDef
} from './config';

let locationStrategyClass: any = HashLocationStrategy;

// pull the configurations from Meta and see what type of location strategy should be use
if (window.appUrlStrategy === 'path') {
    // we will switch over to using path strategy
    locationStrategyClass = PathLocationStrategy;
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        InViewportModule,
        AngularResizedEventModule,

        // Ability
        AppAbilityModule,
        // Routing
        AppRoutingModule,

        // App specific modules
        AdminModule,
        AuthModule,
        CoreModule,
        InventoryModule,
        ReportModule,
        SfdcModule,
        SystemModule,
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        { provide: APP_CONFIG, useValue: AppConfigDef },
        { provide: DASHBOARD_CONFIG, useValue: DashboardConfigDef },
        { provide: MODEL_CONFIG, useValue: ModelConfigDef },
        { provide: NAV_CONFIG, useValue: NavConfigDef },
        AuthGuard, AcceptTcGuard, FinishGuard, LpTagGuard, OauthGuard, ResetPasswordGuard, RtaGuard, TdiDemoGuard,
        { provide: LocationStrategy, useClass: locationStrategyClass },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
